import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';
import React from 'react';

import analytics, {trackEvent} from 'analytics.js';
import autobind from 'common/decorators/autobind.js';

import {
    ChipV2,
    DropdownV2,
    LinkV2,
    HeaderTitleV2,
} from '@HealthShareAU/hs-component-library';

import {
    NEARBY_HOSPITAL_CONTACT_TYPE,
    HOSPITAL_CONTACT_TYPE,
    PROFILE_CONTACT_TYPE,
    PRACTICE_GRP_CONTACT_TYPE,
    PRACTICE_LOC_CONTACT_TYPE,
} from 'core/constants.js';

export default class ContactModalV2 extends React.Component {
    static propTypes = {
        contactId: PropTypes.number.isRequired,
        contactName: PropTypes.string.isRequired,
        contactType: PropTypes.oneOf([
            NEARBY_HOSPITAL_CONTACT_TYPE,
            HOSPITAL_CONTACT_TYPE,
            PROFILE_CONTACT_TYPE,
            PRACTICE_GRP_CONTACT_TYPE,
            PRACTICE_LOC_CONTACT_TYPE,
        ]),
        colorTheme: PropTypes.shape({
            color: PropTypes.string,
        }),
        customClass: PropTypes.string,
        healthFundId: PropTypes.number,
        isEnhanced: PropTypes.bool,
        items: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                address: PropTypes.string,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                phones: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ),
                practiceUrl: PropTypes.string,
                type: PropTypes.oneOf([
                    NEARBY_HOSPITAL_CONTACT_TYPE,
                    HOSPITAL_CONTACT_TYPE,
                    PROFILE_CONTACT_TYPE,
                    PRACTICE_GRP_CONTACT_TYPE,
                    PRACTICE_LOC_CONTACT_TYPE,
                ]).isRequired,
            }).isRequired,
        ).isRequired,
        location: PropTypes.string,
        phoneNumber: PropTypes.string,
        selectedItem: PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.oneOf([
                NEARBY_HOSPITAL_CONTACT_TYPE,
                HOSPITAL_CONTACT_TYPE,
                PROFILE_CONTACT_TYPE,
                PRACTICE_GRP_CONTACT_TYPE,
                PRACTICE_LOC_CONTACT_TYPE,
            ]).isRequired,
        }),
    };

    static defaultProps = {
        items: [],
    };

    constructor(props) {
        super(props);

        const {items, phoneNumber} = props;
        let activeItem = items[0];
        const activePhoneNumber = this.fetchDisplayPhoneNumber(activeItem);
        this.state = {
            activeItem,
            displayPhone: phoneNumber ? phoneNumber : activePhoneNumber,
            practiceChanged: false,
            eventId: null,
        };
    }

    @autobind
    fetchDisplayPhoneNumber(activeItem) {
        return (
            activeItem.phones?.[0].number ||
            activeItem.phoneNumbers?.[0].number
        );
    }
    async componentDidMount() {
        const {activeItem, displayPhone} = this.state;
        const eventId = await this.trackPhone(activeItem, displayPhone);
        this.setState({eventId});
    }

    async trackPhone(activeItem, displayPhone, eventId) {
        let eventName;
        let trackData;
        const {contactId, healthFundId} = this.props;
        switch (activeItem.type) {
            case NEARBY_HOSPITAL_CONTACT_TYPE:
                return null;
            case HOSPITAL_CONTACT_TYPE:
                return trackEvent('/api/hospitals/v1/events/phone-clicks/', {
                    hospital: activeItem.id,
                    phone: displayPhone,
                });
            case PRACTICE_GRP_CONTACT_TYPE:
                eventName = 'practiceGroupPhoneClick';
                trackData = {
                    'practiceGroupId': activeItem.id,
                    'phoneNumber': displayPhone,
                };
                break;
            case PRACTICE_LOC_CONTACT_TYPE:
                eventName = 'practiceLocationPhoneClick';
                trackData = {
                    'practiceLocationId': activeItem.id,
                    'phoneNumber': displayPhone,
                };
                break;
            case PROFILE_CONTACT_TYPE:
                eventName = 'practitionerPhoneClick';
                trackData = {
                    ...(healthFundId ? {'health_fund': healthFundId} : {}),
                    'practitioner': contactId,
                    'practice_position': activeItem.id,
                    'phone_number': displayPhone,
                };
                break;
            default:
                throw new Error('Invalid type for tracking phone clicks');
        }
        return analytics.track(eventName, trackData, eventId);
    }

    async updateSelectedItem(event) {
        const activeItem = {...event};
        const displayPhone = this.fetchDisplayPhoneNumber(activeItem);
        this.setState({
            activeItem,
            displayPhone,
            practiceChanged: true,
        });

        const updateEvent = event.type === this.state.activeItem.type;

        const eventId = await this.trackPhone(
            event,
            displayPhone,
            updateEvent ? this.state.eventId : undefined,
        );
        this.setState({eventId});
    }

    // Find a matching item given a key or return first one if couldn't find anything
    findActiveItem(key) {
        return (
            this.props.items.find((item) => this.getKey(item) === key) ||
            this.props.items[0]
        );
    }

    getKey(item) {
        return `${item.type}-${item.id}`;
    }

    render() {
        const {
            colorTheme,
            contactName,
            customClass,
            isEnhanced,
            items,
            location,
        } = this.props;
        const {displayPhone} = this.state;
        const {address, name} = this.state.activeItem;
        const googlePreviewUrl = address
            ? `https://www.google.com/maps/preview/?q=${address}`
            : '';
        const telNumber = displayPhone.replace(/\s/g, '');
        let PromotionsMessage;
        if (location === PROFILE_CONTACT_TYPE) {
            PromotionsMessage = require('promotions/components/PromotionsMessage.js')
                .default;
        }
        return (
            <div className={`hs-contact-modal-v2 ${customClass || ''}`}>
                <HeaderTitleV2
                    heading={`Contact ${contactName}`}
                    headerType="submain"
                />
                <strong className="hs-contact-modal-v2__highlighted-number">
                    <LinkV2
                        colorTheme={colorTheme}
                        href={`tel:${telNumber}`}
                        text={displayPhone}
                    />
                </strong>
                {items.length > 1 && address && (
                    <DropdownV2
                        options={items}
                        onChange={(event) => this.updateSelectedItem(event)}
                        value={this.state.activeItem || items[0]}
                        colorTheme={colorTheme}
                    ></DropdownV2>
                )}
                {this.state.activeItem?.address && (
                    <div className="hs-contact-modal-v2__info">
                        <strong>{this.state.activeItem?.name}</strong>
                        <ChipV2 colorTheme={colorTheme} icon="markerPin">
                            {googlePreviewUrl ? (
                                <LinkV2
                                    href={googlePreviewUrl}
                                    target="_blank"
                                    text={this.state.activeItem?.address}
                                />
                            ) : (
                                this.state.activeItem?.address
                            )}
                        </ChipV2>
                    </div>
                )}
                <p className="hs-contact-modal-v2__footer">
                    {"Don't forget to mention you found "}
                    <strong>{contactName}</strong>
                    {' on HealthShare. '}
                    {isEnhanced && (
                        <span>
                            {'As they are a '}
                            <strong>{'HealthShare partner'}</strong>
                            {
                                ', you may be able to find a more suitable appointment.'
                            }
                        </span>
                    )}
                </p>
                {PromotionsMessage && <PromotionsMessage />}
            </div>
        );
    }
}
